.social_links a                                                             {   display: inline-block;font-size: 23px;padding: 0px 10px;float: left;text-decoration: none;}  
.ServicesIcon i                                                             {   list-style: none;box-shadow: 0px 0px 20px 6px #ececec;border-radius: 50%;width: 66px;height: 66px;color: #000033;display: flex;flex-direction: row;justify-content: center;align-items: center;transition: all .5s ease-in-out;margin-bottom: 20px;font-size: 20px;}
.servicesGrid::after                                                        {   content: '';border-bottom: 4px solid #e0e0e0;width: 50%;display: block;margin: 8px 0px 30px 0px;position: relative;}
.servicesGrid:hover::after                                                  {   border-bottom: 4px solid #000033 !important;width: 100%;transition: width .8s;}
.servicesGrid:hover .ServicesIcon i                                         {   background-color: #000033;color: #ffffff;animation: fa-spin 2s infinite linear;}
.servicesGrid:hover .subHeaderLeft                                          {   color: #000033;}
@-webkit-keyframes fa-spin                                                  {   0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}
@keyframes fa-spin                                                          {   0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}
#GradGrowBusiness                                                           {   padding: 60px 0px 0px 0px;margin-top: 60px;width: 100%;height: 100%;}
.imgLandingBusiness                                                         {   width: 80%;padding-bottom: 160px;}
.paraheadertxt                                                              {   padding:0px 60px 0px 60px;}
.AboutAppCard                                                               {   border-radius: 100px;padding: 30px;margin-bottom: -75px;box-shadow: 7px 9px 17px #00000029;}
.AboutAppCard h1                                                            {   color:#000033;font-weight: 600;font-size: 30px;}
.greyBg                                                                     {   background-color: #000033;}
.imgQuotes                                                                  {   width: 40px;}
.cardCustomers                                                              {   margin:20px;padding:20px;border: 2px solid #c49e70;background-color: #000033;}
.customerPara                                                               {   font-weight: 400;font-size: 14px;line-height: 30px;letter-spacing: 0px;color: #847E7E;margin-top: 20px;padding: 0px 140px 0px 140px;font-size: 18px;line-height: 30px !important;}
.subHead                                                                    {   text-align: left;font-weight: 600;font-size: 26px;color: #4b4b4b;margin-top: -26px;}
.txtCount                                                                   {   border-right: 3px solid #000033;}
.nav-pills .nav-link.active, .nav-pills 
.show>.nav-link                                                             {   color: #ffffff !important;background: linear-gradient(to right, #000033, #000000);font-size: 14px;font-weight: 600;border-radius: 50px;}.nav-pills .nav-link {text-align: left;color: #4B4B4B !important;box-shadow: 1px 3px 9px 5px #e2e2e2;margin-bottom: 40px;border-radius: 60px;font-weight: 400;padding: 12px 12px 12px 30px;font-size: 14px;}#cursor-dot{background-color: rgb(0, 0, 0) !important;}
.paraContent                                                                {   text-align:center;padding:0px 150px 20px 150px;}
.content                                                                    {   padding: 20px;}
/* Box HOver Border Animation */
.feature-box:hover:before, .feature-box:hover:after                         {   transform: scaleY(1);transition-duration: 0.5s;}
.feature-box:before, .feature-box:after                                     {   height: 100%;width: 4px;transform: scaleY(0);transition-duration: 0.5s;}
.feature-box:before                                                         {   transform-origin: right top 0;}
.feature-box:before, .feature-box .content:before                           {   right: 0;top: 0;transition-duration: 0.5s;}
.feature-box:hover .content:before, .feature-box:hover .content:after       {   transform: scaleX(1);transition-duration: 0.5s;}
.feature-box .content:before, .feature-box .content:after                   {   width: 100%;height: 4px;transform: scaleX(0);transition-duration: 0.5s;}
.feature-box .content:after                                                 {   transform-origin: left top 0;}
.feature-box:after, .feature-box .content:after                             {   left: 0;bottom: 0;transition-duration: 0.5s;}
.feature-box:before, .feature-box .content:before, .feature-box:after, 
.feature-box .content:after                                                 {   display: inline-block;position: absolute;content: "";background-color: #C49E70;z-index: 1;}
.feature-box .content:before                                                {   transform-origin: right top 0;}
.feature-box .content:before, .feature-box .content:after                   {   width: 100%;height: 3px;transform: scaleX(0);transition-duration: 0.5s;}
.feature-box .content:before, .feature-box .content:after                   {   width: 100%;height: 3px;transform: scaleX(0);transition-duration: 0.5s;}
.feature-box .content:after                                                 {   transform-origin: top left 0;}
.feature-box:after, .feature-box .content:after                             {   left: 0;bottom: 0;transition-duration: 0.5s;}
.feature-box:before, .feature-box:after                                     {   height: 100%;width: 3px;transform: scaleY(0);transition-duration: 0.5s;}
.feature-box:after                                                          {   transform-origin: left bottom 0;}
.feature-box:after, .feature-box .content:after                             {   left: 0;bottom: 0;transition-duration: 0.5s;}
.servicesTabsImg                                                            {   width:100%;height: 240px;object-fit: cover;}
#ContactForm                                                                {   padding: 0px 0px 0px 0px;}
.form-label,.form-label:hover,.form-label:active                            {   text-align: left;color: #C49E70;float: left;margin: 20px 0px 20px 0px;}
/* Flipcard Animation */
.flip-card                                                                  {   background-color: transparent;width: 100%;height: 400px;margin-bottom: 40px;perspective: 1000px;}
.flip-card-inner                                                            {   position: relative;width: 100%;height: 100%;text-align: center;transition: transform 0.6s;transform-style: preserve-3d;box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);}
.flip-card:hover .flip-card-front                                           {   cursor: pointer !important;}
.flip-card-front, .flip-card-back                                           {   position: absolute;width: 100%;height: 100%;-webkit-backface-visibility: hidden;backface-visibility: hidden;}
.flip-card-front                                                            {   background-color: transparent;color: black;overflow: hidden;}
.flip-card-back                                                             {   background-color: #C49E70;padding: 20px;color: white;transform: rotateY(180deg);}
.flip-card:hover .flip-card-front img                                       {   height: 250px;transition: height 0.s ease-in;}
.flip-card-front img                                                        {   transition: height 0.5s ease-in;height: 400px;}
.contentCard                                                                {   padding: 20px 10px 10px 10px;}
#our_services                                                               {   background-color: #000033;padding: 80px 0px 80px 0px;}
.fontOurService                                                             {   font-size: 20px;margin-bottom: 20px;}