/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,200;1,300;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,300;0,400;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
html *                                                                  {   font-family: 'Varela Round', sans-serif;}
body                                                                    {   overflow-x: hidden;}
/* End Google Fonts */
/* Img Section */
.logoHeadertran                                                         {   width: 300px;padding: 0px 0px 0px 20px;}
.logoHeaderWhite                                                        {   width: 180px;padding: 0px 0px 0px 20px;}
/* End Img Section */
/* Text Selection */
::selection                                                             {   color: #ffffff;background: #000033;}
/* End text Selection */
  /* Wave Effects */
.wave-effect                                                            {   position: absolute;left: 0;right: 0;bottom: 0;margin: auto;height: 150px;overflow: hidden;}
.shape-one                                                              {   z-index: 15;opacity: 0.5}
.shape-two                                                              {   z-index: 10;opacity: 0.75}
.shape-three                                                            {   z-index: 5}
.wave                                                                   {   position: absolute;left: 0;width: 200%;height: 100%;background-repeat: repeat no-repeat;background-position: 0 bottom;transform-origin: center bottom}
.waves-shape                                                            {   position: absolute;bottom: -1px;width: 100%;height: 100%;overflow: hidden}
.wave-one                                                               {   background-size: 50% 100px}
.wave-two                                                               {   background-size: 50% 120px}
.wave-anim .wave-two                                                    {   animation: move_wave 10s linear infinite}
.wave-three                                                             {   background-size: 50% 100px}
.wave-anim .wave-three                                                  {   animation: move_wave 15s linear infinite}
@keyframes move_wave                                                    {   0% {transform: translateX(0) translateZ(0) scaleY(1)}50% {transform: translateX(-25%) translateZ(0) scaleY(0.55)}100% {transform: translateX(-50%) translateZ(0) scaleY(1)}}
/* End Wave Effects */
#copyrights                                                             {   background-color: #C49E70;    padding: 20px 0px 0px 0px;}
.crFooter                                                               {   color:#000033;font-weight: 600;}
.container, .container-fluid, .container-lg,
.container-md, .container-sm, .container-xl                             {   padding-left: 0px !important;}
/* Header and text */
.headerLeft                                                             {   text-align: center;font-size: 30px;font-weight: 400;color: #4B4B4B;padding: 0px 0px 40px 0px;}
.subHeaderLeft                                                          {   line-height: 25px;text-align: center;font-size: 16px;font-weight: 500;color: #5d5b5b;padding: 0px 0px 10px 0px;}
.txtCntrt                                                               {   text-align: center;font-size: 14px;font-weight: 400;color: #999696;}
.txtJustify                                                             {   text-align: justify !important;}
.width100                                                               {   width: 100%;}
.gradientBg                                                             {   background: linear-gradient(to right, #000033, #000000);}
.textCenter                                                             {   text-align: center !important;}
.mt-20                                                                  {   margin-top:20px}
.mt-40                                                                  {   margin-top:40px}
.mt-60                                                                  {   margin-top:60px}
.mt-80                                                                  {   margin-top:80px}
.scroll-to-top                                                          {   position: fixed;bottom: 0.5rem;right: 0.5rem;animation: fadeIn 700ms ease-in-out 1s both;}
.casCadeStyle                                                           {   color:#ececec;}
.casCadeStyle                                                           {   color:#000000;}
.bg-video-wrap                                                          {   position: relative;overflow: hidden;width: 100%;height: 100vh;margin-top: -100px;}
video                                                                   {   object-fit: cover;width: 100vw;height: 100vh;position: relative;top: 0;left: 0;}
.overlay                                                                {   width: 100%;height: 100vh;position: absolute;top: 0;left: 0;background-image: linear-gradient(45deg, rgba(0,0,0,.3) 50%, rgba(0,0,0,.7) 50%);background-size: 3px 3px;z-index: 2;}
.OverLaytxt                                                             {   font-family: 'Anton', sans-serif !important;text-align: left;color: #fff;font-size: 64px;font-weight: 500;color: #ffffff;position: absolute;top: 200px;bottom: 0;left: 80px;right: 0;margin-top: 100px;margin: auto;z-index: 3;}
.events-wrapper li                                                      {   background:none !important}
hr                                                                      {   border: 1px solid #C49E70;}
.imgAbout                                                               {   width:100%;height:450px;object-fit: cover;transition: height 0.15s ease-out;}
.imgCapabilities                                                        {   width:100%;height: 400px;object-fit: cover;}
.fa                                                                     {   font-size: 80px !important;color: #bfbdbd;margin-bottom: 20px;}
.form-control                                                           {   display: block;width: 100%;height: calc(1.5em + .75rem + 2px);padding: 20px 20px 20px 20px;font-size: 1rem;font-weight: 400;line-height: 1.5;color: #C49E70;background-color: transparent;background-clip: padding-box;border: 2px solid #C49E70;border-radius: 0px;outline: none !important;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
textarea:focus, input:focus                                             {   outline: none !important;}
::placeholder                                                           {   color: #C49E70 !important;opacity: 1; /* Firefox */}
.clrWhite                                                               {   color:#ffffff !important;}
.clrBlue                                                                {   color:#000033 !important;}
.clGold                                                                 {   color:#C49E70 !important;}
.imgOs                                                                  {   margin: 0px 0px 30px 0px;text-align: center;height: 80px;}
.imgTech                                                                {   width:140px;height: 140px; object-fit:contain;}
.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item          {   display: none !important;}
#ourTech                                                                {   padding: 40px 0px 0px 0px;}
.navbar-collapse                                                        {   z-index: 999;}
.alice-carousel__dots-item:not(.__custom)                               {   width: 12px !important;height: 12px !important;}
.alice-carousel__dots-item:not(.__custom).__active                      {   background-color: #C49E70 !important;}
.btn-primary,.btn-primary:hover,.btn-primary:active,.btn-primary:focus  {   color: #C49E70;background-color: transparent;border: 2px solid #C49E70;border-radius: 0px;margin-top: 40px;}
.modal-backdrop.show                                                    {   opacity: 1 !important;}
.modal-backdrop.fade                                                    {   opacity: 0;}
.modal-backdrop                                                         {   position: fixed;top: 0;left: 0;z-index: 101;width: 100vw;height: 100vh;background-color: #ffffff !important;}
.modal                                                                  {   z-index: 101;}
.modal-content                                                          {   background-color: #ffffff !important;border: 0px solid rgba(0,0,0,.2);}
.modal-header                                                           {   border-bottom: 3px solid #C49E70;}
.swal2-container                                                        {   z-index: 99 !important;}
.aboutLeftImg                                                           {   width:100%;height: 100%;object-fit: cover;}
.txtAnton                                                               {   font-family: 'Anton', sans-serif !important;font-size: 34px;color: #000033;}
.txtAntonSub                                                            {   font-family: 'Anton', sans-serif !important;font-size: 28px;color: #000033;}
.txtGoldSub                                                             {   font-size: 16px;color: #935a17;font-weight: 400;padding:0px 50px 0px 50px;}
.horizontalTimeline                                                     {   padding: 0px 100px 0px 100px;}
.customContainer                                                        {   width: 100%;padding: 0 1%;margin: 0 auto;}
.one-half                                                               {   width: 39%; } 
.second-half                                                            {   width: 39%; } 
.rowC                                                                   {   margin: 1em 0;	}
#bgGold                                                                 {   background-color: #000033;padding: 80px 0px 20px 0px;}
.floating                                                               {   animation-name: floating; animation-duration: 5s; animation-iteration-count: infinite; animation-timing-function: ease-in-out; margin-left: 30px; margin-top: 5px; } 
@keyframes floating                                                     {   0% { transform: translate(0,  0px); } 50%  { transform: translate(0, 250px); } 100%   { transform: translate(0, -0px); }     } 
.circle-1, .circle-2                                                    {   fill: #c49e70;animation: scroll 2s infinite linear;opacity:0;}
.circle-2                                                               {   animation-delay: 1s;}
.mouseStroke                                                            {   stroke: #c49e70;stroke-width: 3px;}
.scroll-to-top                                                          {   bottom: 66px;}
/* Scroll to top */
.svgScroll                                                              {   margin: 0px 10px 0px 0px;width: 40px;border-radius: 40px;z-index: 100;}
@keyframes scroll                                                       {   0% {cy: 14;opacity: 0;}45%, 55% {opacity: 1;cy: 9;}100% {cy: 4;opacity: 0;}}
/* End Scroll to top */
/* Our Services Svg */
#our_services svg                                                       {   width: 200px;height: 250px;margin: 0px 0px 40px 0px;}
@keyframes dash                                                         {   from {stroke-dashoffset: 822;}to {stroke-dashoffset: 0;}}
/* Svg One */
#svgOne path                                                            {   fill:none;stroke: #C49E70;stroke-width: 0.6px;}
.svgBox:hover #svgOne path                                              {   stroke-dasharray: 100;stroke-dashoffset: 100;animation: dash 30s linear alternate infinite;}
/* Svg Two */
#svgTwo path                                                            {   fill:none;stroke: #C49E70;stroke-width: 5px;}
.svgBox:hover #svgTwo path                                              {   stroke-dasharray: 800;stroke-dashoffset: 800;animation: dash 4s linear alternate infinite;}
/* Svg Three */
#svgThree path                                                          {   fill:none;stroke: #C49E70;stroke-width: 5px;}
.svgBox:hover #svgThree path                                            {   stroke-dasharray: 800;stroke-dashoffset: 800;animation: dash 4s linear alternate infinite;}
.sectionAbt                                                             {   position: relative;}
.centered                                                               {   position: absolute;top: 30%;left: 50%;transform: translate(-50%, -50%);}
#ourSolutions                                                           {   padding: 0px 30px 0px 30px !important;}
.contactAddress                                                         {   text-align: left !important;padding: 0px 50px 50px 50px;}
.contactAddress img                                                     {   width:100%;}
.contactAddress .fa                                                     {   font-size: 30px !important;color: #c49e70;margin-right: 20px;}
.addressDetails                                                         {   font-size: 18px;font-weight: 400;color: #c49e70;}
.logoClients                                                            {   width: 200px;}
.navbar-dark .navbar-toggler,.navbar-toggler:hover,
.navbar-toggler:focus,.navbar-toggler:active                            {   color: rgba(255,255,255,.5);border-color: transparent !important;outline: none !important;}
.fa-ellipsis-h                                                          {   font-size: 40px !important;}
.txtCenter                                                              {   font-size: 20px !important;color: #000033;text-align: center;margin: 20px 0px 20px 0px;}
.imgModal                                                               {   width: 100px;margin: 20px 0px 0px 0px;}
/* About Us Timeline Design */
#aboutUs .timeline                                                      {   list-style: none;padding: 20px 0 20px;position: relative;margin-bottom: 60px;}
#aboutUs .timeline:before                                               {   top: 0;bottom: 0;position: absolute;content: " ";width: 3px;background-color: #eeeeee;left: 50%;margin-left: -1.5px;}
#aboutUs .timeline > li                                                 {   margin-bottom: 6px;position: relative;}
#aboutUs .timeline > li:before, .timeline > li:after                    {   content: " ";display: table;}
#aboutUs .timeline > li:after                                           {   clear: both;}
#aboutUs .timeline > li:before, .timeline > li:after                    {   content: " ";display: table;}
#aboutUs .timeline > li:after                                           {   clear: both;}
#aboutUs .timeline > li > .timeline-panel                               {   width: 46%;float: left;border: 1px solid #d4d4d4;border-radius: 2px;padding: 20px;position: relative;-webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);}
#aboutUs .timeline > li > .timeline-panel:before                        {   position: absolute;top: 26px;right: -15px;display: inline-block;border-top: 15px solid transparent;border-left: 15px solid #ccc;border-right: 0 solid #ccc;border-bottom: 15px solid transparent;content: " ";}
#aboutUs .timeline > li > .timeline-panel:after                         {   position: absolute;top: 27px;right: -14px;display: inline-block;border-top: 14px solid transparent;border-left: 14px solid #fff;border-right: 0 solid #fff;border-bottom: 14px solid transparent;content: " ";}
#aboutUs .timeline > li > .timeline-badge                               {   background-color: #000033;color: #fff;width: 50px;height: 50px;line-height: 50px;font-size: 1.4em;text-align: center;position: absolute;top: 16px;left: 50%;margin-left: -25px;border-top-right-radius: 50%;border-top-left-radius: 50%;border-bottom-right-radius: 50%;border-bottom-left-radius: 50%;}
#aboutUs .timeline > li.timeline-inverted > .timeline-panel             {   float: right;}
#aboutUs .timeline > li.timeline-inverted > .timeline-panel:before      {   border-left-width: 0;border-right-width: 15px;left: -15px;right: auto;}
#aboutUs .timeline > li.timeline-inverted > .timeline-panel:after       {   border-left-width: 0;border-right-width: 14px;left: -14px;right: auto;}
#aboutUs .timeline-badge.primary                                        {   background-color: #2e6da4 !important;}
#aboutUs .timeline-badge.success                                        {   background-color: #3f903f !important;}
#aboutUs .timeline-badge.warning                                        {   background-color: #f0ad4e !important;}
#aboutUs .timeline-badge.danger                                         {   background-color: #d9534f !important;}
#aboutUs .timeline-badge.info                                           {   background-color: #5bc0de !important;}
#aboutUs .timeline-title                                                {   margin-top: 0;color: inherit;}
#aboutUs .timeline-body > p, .timeline-body > ul                        {   margin-bottom: 0;}
#aboutUs .timeline-body > p + p                                         {   margin-top: 5px;}
#aboutUs .fa                                                            {   font-size: 20px !important;color: #ffffff;}
#aboutUs ul li:hover .timeline-badge                                    {   background-color: #C49E70 !important;}
#aboutUs ul li:hover .timeline-panel                                    {   box-shadow: 0 25px 25px #c6c6c6 !important;}
.txtRight                                                               {   text-align: right;}
.txtLeft                                                                {   text-align: left;}
.fnt-30                                                                 {   font-size: 30px;}
.fnt-22                                                                 {   font-size: 22px;}